<template>
  <div class="Attachments__photo-container">
    <div
      v-for="(item, index) in attachmentsWithType"
      :key="index"
      class="Attachments__photo-item"
    >
      <img
        v-img
        v-if="!item.videoThumbnail"
        :src="item.attachment_url"
        class="Attachments__photo"
        alt="attachment"
      />
      <template v-if="item.videoThumbnail">
        <img
          :src="item.videoThumbnail"
          class="Attachments__photo"
          alt="attachment"
          @click.prevent="openVideoPopup"
        />
        <button class="play-icon" @click.prevent="openVideoPopup">
          <img alt="Play Icon" src="@/assets/svg/play-icon.svg" />
        </button>
        <video-popup
          v-if="isOpenVideoPopup"
          :video-source="item.attachment_url"
          @close="closeVideoPopup"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { Video } from 'video-metadata-thumbnails';
import VideoPopup from '@/components/common/VideoPopup.vue';

export default {
  name: 'Attachments',

  components: { VideoPopup },

  props: {
    attachments: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    attachmentsWithType: [],
    isOpenVideoPopup: false,
  }),

  mounted() {
    this.setAttachemnts();
  },

  methods: {
    openVideoPopup() {
      this.isOpenVideoPopup = true;
    },
    closeVideoPopup() {
      this.isOpenVideoPopup = false;
    },
    async setAttachemnts() {
      this.attachmentsWithType = await Promise.all(
        this.attachments.map(async (attachment) => {
          const type = this.getAttachmentType(attachment);
          const videoThumbnail = type.includes('video')
            ? await this.getVideoThumbnail(attachment)
            : '';
          return { ...attachment, type, videoThumbnail };
        })
      );
    },
    async getVideoThumbnail(item) {
      const video = new Video(item.attachment_url);
      const videoThumbnails = await video.getThumbnails({ start: 0, end: 0 });
      const videoThumbnail = await URL.createObjectURL(videoThumbnails[0].blob);
      return videoThumbnail;
    },
    getAttachmentType(item) {
      const url = item.attachment_url;
      const xhr = new XMLHttpRequest();
      xhr.open('get', url, false);
      xhr.send(null);
      return xhr.getResponseHeader('content-type');
    },
  },
};
</script>

<style lang="scss" scoped>
.Attachments {
  &__photo-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -5px;

    @media (max-width: $bp-xxs-max) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  &__photo-item {
    width: 105px;
    max-height: 72px;
    overflow: hidden;
    padding: 5px;
    position: relative;
    cursor: pointer;

    .play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0;
      border: 0;
      outline: 0;
      background: transparent;

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  &__photo {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
  }
}
</style>
